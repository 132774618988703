var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "report-item-card",
    on: {
      "click": _vm.goDetail
    }
  }, [_c('div', {
    staticClass: "report-date"
  }, [_vm._v(" 日期：2022-08-02 ")]), _c('div', {
    staticClass: "report-action"
  }, [_vm._v(" 查看详情 > ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };